export default {
    Dashboards: 'لوحات القيادة',
    CRM: 'CRM',
    Analytics: 'تحليلات',
    eCommerce: 'التجارة الإلكترونية',
    Calendar: 'تقويم',
    Chat: 'محادثة',
    Email: 'بريد إلكتروني',
    Invoice: 'فاتورة',
    List: 'قائمة',
    Preview: 'معاينة',
    Edit: 'يحرر',
    Add: 'يضيف',
    User: 'المستعمل',
    'User List': 'قائمة المستخدم',
    'User View': 'عرض المستخدم',
    'APPS AND PAGES': 'تطبيقات وصفحات',
    Pages: 'الصفحات',
    Authentication: 'المصادقة',
    'Login V1': 'تسجيل الدخول V1',
    'Login V2': 'تسجيل الدخول V2',
    'Register V1': 'تسجيل V1',
    'Register V2': 'تسجيل V2',
    'Forgot Password V1': 'نسيت كلمة المرور V1',
    'Forgot Password V2': 'نسيت كلمة المرور V2',
    'Reset Password V1': 'إعادة تعيين كلمة المرور V1',
    'Reset Password V2': 'إعادة تعيين كلمة المرور V2',
    Miscellaneous: 'متفرقات',
    'Under Maintenance': 'تحت الصيانة',
    Error: 'خطأ',
    'Coming Soon': 'قريبا',
    'Not Authorized': 'غير مخول',
    'Knowledge Base': 'قاعدة المعرفة',
    'Account Settings': 'إعدادت الحساب',
    Pricing: 'التسعير',
    FAQ: 'التعليمات',
    'USER INTERFACE': 'واجهة المستخدم',
    Typography: 'الطباعة',
    Icons: 'الأيقونات',
    Gamification: 'التلعيب',
    Cards: 'البطاقات',
    Basic: 'أساسي',
    Statistics: 'إحصائيات',
    Advance: 'يتقدم',
    Actions: 'أجراءات',
    Chart: 'جدول',
    Components: 'عناصر',
    Alert: 'يحذر',
    Avatar: 'الصورة الرمزية',
    Badge: 'شارة',
    Button: 'زر',
    Menu: 'لائحة الطعام',
    'Expansion Panel': 'لوحة التوسع',
    Dialog: 'حوار',
    Pagination: 'ترقيم الصفحات',
    Tabs: 'نوافذ التبويب',
    Tooltip: 'تلميح',
    Chip: 'رقاقة',
    Snackbar: 'مطعم الوجبات الخفيفة',
    Stepper: 'السائر',
    Timeline: 'الجدول الزمني',
    Treeview: 'تريفيو',
    'FORMS & TABLES': 'النماذج والجداول',
    'Forms Elements': 'عناصر الأشكال',
    Autocomplete: 'الإكمال التلقائي',
    Checkbox: 'خانة اختيار',
    Combobox: 'صندوق التحرير',
    'File Input': 'إدخال الملف',
    Radio: 'مذياع',
    'Range Slider': 'نطاق المنزلق',
    Select: 'يختار',
    Slider: 'المنزلق',
    Switch: 'يحول',
    Textarea: 'تيكستاريا',
    Textfield: 'مجال التحرير مكان كتابة النص',
    'Date Picker': 'منتقي التاريخ',
    'Month Picker': 'منتقي الشهر',
    'Time Picker': 'منتقي الوقت',
    Rating: 'تقييم',
    'Form Layout': 'تخطيط النموذج',
    'Form validation': 'التحقق من صحة النموذج',
    Tables: 'الجداول',
    'Simple Table': 'جدول بسيط',
    Datatable: 'جدول البيانات',
    'CHARTS AND MAPS': 'الرسوم البيانية والخرائط',
    Charts: 'الرسوم البيانية',
    'Apex Chart': 'مخطط أبيكس',
    Chartjs: 'تشارتجس',
    'Leaflet Maps': 'خرائط المطويات',
    Others: 'آحرون',
    'Menu Levels': 'مستويات القائمة',
    'Menu Level 2.1': 'مستوى القائمة 2.1',
    'Menu Level 2.2': 'مستوى القائمة 2.2',
    'Menu Level 3.1': 'مستوى القائمة 3.1',
    'Menu Level 3.2': 'مستوى القائمة 3.2',
    'Disabled Menu': 'قائمة المعوقين',
    'Raise Support': 'رفع الدعم',
    Documentation: 'توثيق',
    'Charts & Maps': 'الرسوم البيانية والخرائط',
    Apps: 'تطبيقات',
    'User Interface': 'واجهة المستخدم',
    'Forms & Tables': 'النماذج والجداول',
};
